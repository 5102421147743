import { DEFAULT_LOCALE } from '@front/shared';
import {
  EActionType,
  FooterWithSitemap,
  RichText,
  TAction,
  toLinkProps,
} from '@front/shared/ds';
import { addJurisdictionSupport } from '@front/shared/utils';
import { Footer as TFooter, Locale, Jurisdiction } from '@shared/master-types';
import { useRouter } from 'next/router';
import React from 'react';

import { useActions } from '../../../features/actions/useActions.hook';

export type TFooterWithSitemapConnectedConnectedProps = Extract<
  Required<TFooter>['footer'][number],
  { blockType: 'footer-with-sitemap' }
> & {
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
};

const FooterWithSitemapConnected = (
  props: TFooterWithSitemapConnectedConnectedProps,
): JSX.Element | null => {
  const {
    locales = [],
    description,
    callToAction,
    contacts,
    menu,
    jurisdictionList,
    jurisdictionItem,
  } = props;

  const router = useRouter();

  const ctaActions = addJurisdictionSupport({
    actionList: useActions(callToAction?.actions),
    jurisdictionList: jurisdictionList,
    localeList: locales,
    slug: jurisdictionItem?.slug,
  });

  const menuWithActions = menu.map(menuItem => ({
    label: menuItem.label,
    links: addJurisdictionSupport({
      jurisdictionList: jurisdictionList,
      localeList: locales,
      slug: jurisdictionItem?.slug,
      actionList: menuItem.links.map(
        (link): TAction => ({
          type: EActionType.LinkType,
          text: link.text,
          props: {
            children: link?.text,
            ...toLinkProps(String(router.query.locale || DEFAULT_LOCALE), link),
          },
        }),
      ),
    }),
  }));

  return (
    <FooterWithSitemap
      contacts={
        contacts?.map(contact => ({
          label: contact.label,
          type: contact.type,
          value: contact.email || contact.telephone || contact.text,
        })) ?? []
      }
      menu={menuWithActions}
      callToAction={{
        title: callToAction?.title,
        subtitle: callToAction?.subtitle,
        actions: ctaActions,
      }}
      description={<RichText content={description} />}
    />
  );
};

export default FooterWithSitemapConnected;
